@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&family=Raleway:wght@100;400&display=swap);

.flatpickr-calendar {
    background: transparent;
    opacity: 0;
    display: none;
    text-align: center;
    visibility: hidden;
    padding: 0;
    -webkit-animation: none;
            animation: none;
    direction: ltr;
    border: 0;
    font-size: 14px;
    line-height: 24px;
    border-radius: 5px;
    position: absolute;
    width: 307.875px;
    box-sizing: border-box;
    touch-action: manipulation;
    box-shadow: 0 3px 13px rgba(0,0,0,0.08);
  }
  .flatpickr-calendar.open,
  .flatpickr-calendar.inline {
    opacity: 1;
    max-height: 640px;
    visibility: visible;
  }
  .flatpickr-calendar.open {
    display: inline-block;
    z-index: 99999;
  }
  .flatpickr-calendar.animate.open {
    -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
            animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px;
  }
  .flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px);
  }
  .flatpickr-calendar.static.open {
    z-index: 999;
    display: block;
  }
  .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
    box-shadow: none !important;
  }
  .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
    box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  }
  .flatpickr-calendar .hasWeeks .dayContainer,
  .flatpickr-calendar .hasTime .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0;
  }
  .flatpickr-calendar.hasTime .flatpickr-time {
    height: 40px;
    border-top: 1px solid rgba(72,72,72,0.2);
  }
  .flatpickr-calendar.hasTime .flatpickr-innerContainer {
    border-bottom: 0;
  }
  .flatpickr-calendar.hasTime .flatpickr-time {
    border: 1px solid rgba(72,72,72,0.2);
  }
  .flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto;
  }
  .flatpickr-calendar:before,
  .flatpickr-calendar:after {
    position: absolute;
    display: block;
    pointer-events: none;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    left: 22px;
  }
  .flatpickr-calendar.rightMost:before,
  .flatpickr-calendar.arrowRight:before,
  .flatpickr-calendar.rightMost:after,
  .flatpickr-calendar.arrowRight:after {
    left: auto;
    right: 22px;
  }
  .flatpickr-calendar.arrowCenter:before,
  .flatpickr-calendar.arrowCenter:after {
    left: 50%;
    right: 50%;
  }
  .flatpickr-calendar:before {
    border-width: 5px;
    margin: 0 -5px;
  }
  .flatpickr-calendar:after {
    border-width: 4px;
    margin: 0 -4px;
  }
  .flatpickr-calendar.arrowTop:before,
  .flatpickr-calendar.arrowTop:after {
    bottom: 100%;
  }
  .flatpickr-calendar.arrowTop:before {
    border-bottom-color: rgba(72,72,72,0.2);
  }
  .flatpickr-calendar.arrowTop:after {
    border-bottom-color: #42459b;
  }
  .flatpickr-calendar.arrowBottom:before,
  .flatpickr-calendar.arrowBottom:after {
    top: 100%;
  }
  .flatpickr-calendar.arrowBottom:before {
    border-top-color: rgba(72,72,72,0.2);
  }
  .flatpickr-calendar.arrowBottom:after {
    border-top-color: #42459b;
  }
  .flatpickr-calendar:focus {
    outline: 0;
  }
  .flatpickr-wrapper {
    position: relative;
    display: inline-block;
  }
  .flatpickr-months {
    display: flex;
  }
  .flatpickr-months .flatpickr-month {
    border-radius: 5px 5px 0 0;
    background: #42459b;
    color: #fff;
    fill: #fff;
    height: 34px;
    line-height: 1;
    text-align: center;
    position: relative;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow: hidden;
    flex: 1 1;
  }
  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 34px;
    padding: 10px;
    z-index: 3;
    color: #fff;
    fill: #fff;
  }
  .flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
  .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
    display: none;
  }
  .flatpickr-months .flatpickr-prev-month i,
  .flatpickr-months .flatpickr-next-month i {
    position: relative;
  }
  .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
    left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
  }
  /*
        /*rtl:begin:ignore*/
  /*
        /*rtl:end:ignore*/
  .flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
    right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
  }
  /*
        /*rtl:begin:ignore*/
  /*
        /*rtl:end:ignore*/
  .flatpickr-months .flatpickr-prev-month:hover,
  .flatpickr-months .flatpickr-next-month:hover {
    color: #bbb;
  }
  .flatpickr-months .flatpickr-prev-month:hover svg,
  .flatpickr-months .flatpickr-next-month:hover svg {
    fill: #f64747;
  }
  .flatpickr-months .flatpickr-prev-month svg,
  .flatpickr-months .flatpickr-next-month svg {
    width: 14px;
    height: 14px;
  }
  .flatpickr-months .flatpickr-prev-month svg path,
  .flatpickr-months .flatpickr-next-month svg path {
    transition: fill 0.1s;
    fill: inherit;
  }
  .numInputWrapper {
    position: relative;
    height: auto;
  }
  .numInputWrapper input,
  .numInputWrapper span {
    display: inline-block;
  }
  .numInputWrapper input {
    width: 100%;
  }
  .numInputWrapper input::-ms-clear {
    display: none;
  }
  .numInputWrapper input::-webkit-outer-spin-button,
  .numInputWrapper input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
  .numInputWrapper span {
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    border: 1px solid rgba(72,72,72,0.15);
    box-sizing: border-box;
  }
  .numInputWrapper span:hover {
    background: rgba(0,0,0,0.1);
  }
  .numInputWrapper span:active {
    background: rgba(0,0,0,0.2);
  }
  .numInputWrapper span:after {
    display: block;
    content: "";
    position: absolute;
  }
  .numInputWrapper span.arrowUp {
    top: 0;
    border-bottom: 0;
  }
  .numInputWrapper span.arrowUp:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid rgba(72,72,72,0.6);
    top: 26%;
  }
  .numInputWrapper span.arrowDown {
    top: 50%;
  }
  .numInputWrapper span.arrowDown:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(72,72,72,0.6);
    top: 40%;
  }
  .numInputWrapper span svg {
    width: inherit;
    height: auto;
  }
  .numInputWrapper span svg path {
    fill: rgba(255,255,255,0.5);
  }
  .numInputWrapper:hover {
    background: rgba(0,0,0,0.05);
  }
  .numInputWrapper:hover span {
    opacity: 1;
  }
  .flatpickr-current-month {
    font-size: 135%;
    line-height: inherit;
    font-weight: 300;
    color: inherit;
    position: absolute;
    width: 75%;
    left: 12.5%;
    padding: 7.48px 0 0 0;
    line-height: 1;
    height: 34px;
    display: inline-block;
    text-align: center;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
  .flatpickr-current-month span.cur-month {
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    display: inline-block;
    margin-left: 0.5ch;
    padding: 0;
  }
  .flatpickr-current-month span.cur-month:hover {
    background: rgba(0,0,0,0.05);
  }
  .flatpickr-current-month .numInputWrapper {
    width: 6ch;
    width: 7ch\0;
    display: inline-block;
  }
  .flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: #fff;
  }
  .flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: #fff;
  }
  .flatpickr-current-month input.cur-year {
    background: transparent;
    box-sizing: border-box;
    color: inherit;
    cursor: text;
    padding: 0 0 0 0.5ch;
    margin: 0;
    display: inline-block;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    line-height: inherit;
    height: auto;
    border: 0;
    border-radius: 0;
    vertical-align: baseline;
    vertical-align: initial;
    -webkit-appearance: textfield;
    appearance: textfield;
  }
  .flatpickr-current-month input.cur-year:focus {
    outline: 0;
  }
  .flatpickr-current-month input.cur-year[disabled],
  .flatpickr-current-month input.cur-year[disabled]:hover {
    font-size: 100%;
    color: rgba(255,255,255,0.5);
    background: transparent;
    pointer-events: none;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months {
    appearance: menulist;
    background: #42459b;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    height: auto;
    line-height: inherit;
    margin: -1px 0 0 0;
    outline: none;
    padding: 0 0 0 0.5ch;
    position: relative;
    vertical-align: baseline;
    vertical-align: initial;
    -webkit-box-sizing: border-box;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    width: auto;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months:focus,
  .flatpickr-current-month .flatpickr-monthDropdown-months:active {
    outline: none;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background: rgba(0,0,0,0.05);
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: #42459b;
    outline: none;
    padding: 0;
  }
  .flatpickr-weekdays {
    background: #42459b;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    height: 28px;
  }
  .flatpickr-weekdays .flatpickr-weekdaycontainer {
    display: flex;
    flex: 1 1;
  }
  span.flatpickr-weekday {
      color:white !important;
    cursor: default;
    font-size: 90%;
    background: #42459b;
    color: rgba(0,0,0,0.54);
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    flex: 1 1;
    font-weight: bolder;
  }
  .dayContainer,
  .flatpickr-weeks {
    padding: 1px 0 0 0;
  }
  .flatpickr-days {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    width: 307.875px;
    border-left: 1px solid rgba(72,72,72,0.2);
    border-right: 1px solid rgba(72,72,72,0.2);
  }
  .flatpickr-days:focus {
    outline: 0;
  }
  .dayContainer {
    padding: 0;
    outline: 0;
    text-align: left;
    width: 307.875px;
    min-width: 307.875px;
    max-width: 307.875px;
    box-sizing: border-box;
    display: inline-block;
    display: flex;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-around;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
  .dayContainer + .dayContainer {
    box-shadow: -1px 0 0 rgba(72,72,72,0.2);
  }
  .flatpickr-day {
    background: none;
    border: 1px solid transparent;
    border-radius: 150px;
    box-sizing: border-box;
    color: #484848;
    cursor: pointer;
    font-weight: 400;
    width: 14.2857143%;
    flex-basis: 14.2857143%;
    max-width: 39px;
    height: 39px;
    line-height: 39px;
    margin: 0;
    display: inline-block;
    position: relative;
    justify-content: center;
    text-align: center;
  }
  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus {
    cursor: pointer;
    outline: 0;
    background: #e2e2e2;
    border-color: #e2e2e2;
  }
  .flatpickr-day.today {
    border-color: #bbb;
  }
  .flatpickr-day.today:hover,
  .flatpickr-day.today:focus {
    border-color: #bbb;
    background: #bbb;
    color: #fff;
  }
  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: #42459b;
    box-shadow: none;
    color: #fff;
    border-color: #42459b;
  }
  .flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange,
  .flatpickr-day.endRange.startRange {
    border-radius: 50px 0 0 50px;
  }
  .flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange,
  .flatpickr-day.endRange.endRange {
    border-radius: 0 50px 50px 0;
  }
  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
    box-shadow: -10px 0 0 #42459b;
  }
  .flatpickr-day.selected.startRange.endRange,
  .flatpickr-day.startRange.startRange.endRange,
  .flatpickr-day.endRange.startRange.endRange {
    border-radius: 50px;
  }
  .flatpickr-day.inRange {
    border-radius: 0;
    box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2;
  }
  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.notAllowed,
  .flatpickr-day.notAllowed.prevMonthDay,
  .flatpickr-day.notAllowed.nextMonthDay {
    color: rgba(72,72,72,0.3);
    background: transparent;
    border-color: transparent;
    cursor: default;
  }
  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover {
    cursor: not-allowed;
    color: rgba(72,72,72,0.1);
  }
  .flatpickr-day.week.selected {
    border-radius: 0;
    box-shadow: -5px 0 0 #42459b, 5px 0 0 #42459b;
  }
  .flatpickr-day.hidden {
    visibility: hidden;
  }
  .rangeMode .flatpickr-day {
    margin-top: 1px;
  }
  .flatpickr-weekwrapper {
    float: left;
  }
  .flatpickr-weekwrapper .flatpickr-weeks {
    padding: 0 12px;
    border-left: 1px solid rgba(72,72,72,0.2);
  }
  .flatpickr-weekwrapper .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px;
  }
  .flatpickr-weekwrapper span.flatpickr-day,
  .flatpickr-weekwrapper span.flatpickr-day:hover {
    display: block;
    width: 100%;
    max-width: none;
    color: rgba(72,72,72,0.3);
    background: transparent;
    cursor: default;
    border: none;
  }
  .flatpickr-innerContainer {
    display: block;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    background: #fff;
    border-bottom: 1px solid rgba(72,72,72,0.2);
  }
  .flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    box-sizing: border-box;
  }
  .flatpickr-time {
    text-align: center;
    outline: 0;
    display: block;
    height: 0;
    line-height: 40px;
    max-height: 40px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    background: #fff;
    border-radius: 0 0 5px 5px;
  }
  .flatpickr-time:after {
    content: "";
    display: table;
    clear: both;
  }
  .flatpickr-time .numInputWrapper {
    flex: 1 1;
    width: 40%;
    height: 40px;
    float: left;
  }
  .flatpickr-time .numInputWrapper span.arrowUp:after {
    border-bottom-color: #484848;
  }
  .flatpickr-time .numInputWrapper span.arrowDown:after {
    border-top-color: #484848;
  }
  .flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%;
  }
  .flatpickr-time.time24hr .numInputWrapper {
    width: 49%;
  }
  .flatpickr-time input {
    background: transparent;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    color: #484848;
    font-size: 14px;
    position: relative;
    box-sizing: border-box;
    -webkit-appearance: textfield;
    appearance: textfield;
  }
  .flatpickr-time input.flatpickr-hour {
    font-weight: bold;
  }
  .flatpickr-time input.flatpickr-minute,
  .flatpickr-time input.flatpickr-second {
    font-weight: 400;
  }
  .flatpickr-time input:focus {
    outline: 0;
    border: 0;
  }
  .flatpickr-time .flatpickr-time-separator,
  .flatpickr-time .flatpickr-am-pm {
    height: inherit;
    float: left;
    line-height: inherit;
    color: #484848;
    font-weight: bold;
    width: 2%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    align-self: center;
  }
  .flatpickr-time .flatpickr-am-pm {
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
  }
  .flatpickr-time input:hover,
  .flatpickr-time .flatpickr-am-pm:hover,
  .flatpickr-time input:focus,
  .flatpickr-time .flatpickr-am-pm:focus {
    background: #eaeaea;
  }
  .flatpickr-input[readonly] {
    cursor: pointer;
  }
  @-webkit-keyframes fpFadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -20px, 0);
              transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fpFadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -20px, 0);
              transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  
*{
    transition: all 0.6s;
}

html {
    height: 100%;
}

body{
    font-family: 'Lato', sans-serif;
    color: black;
    margin: 0;
}

#main{
    display: flex;
    width: 100%;
    height: 100vh;
    text-align: center;
    flex-direction: column;
    align-items: center;
}

.fof{
	  display: table-cell;
	  vertical-align: middle;
}

.fof h1{
	  font-size: 20px;
	  display: inline-block;
	  padding-right: 12px;
	  -webkit-animation: type .5s alternate infinite;
	          animation: type .5s alternate infinite;
      color: #2f373b;
}

@-webkit-keyframes type{
	  from{box-shadow: inset -3px 0px 0px #888;}
	  to{box-shadow: inset -3px 0px 0px transparent;}
}

@keyframes type{
	  from{box-shadow: inset -3px 0px 0px #888;}
	  to{box-shadow: inset -3px 0px 0px transparent;}
}

/******** INITIAL CSS **********/


body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #f1f2f2 !important;
  -moz-osx-font-smoothing: grayscale;
}

/******** INITIAL CSS **********/

/********* NAVBAR && FOOTER *********/

.pages {
  color: #316685;
  text-align: center;
  font-size: calc(1.5rem + 2vw);
  margin-top: 10%;
}

.navbar {
  background-color: #42459b;
  height: 65px;
  display: flex;
  padding: 0px;
  align-items: center;
  font-size: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  font-weight: 400;
}
.fa-map-marker{
  color: #967a50;
}
.main-header{
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 13px;
}
.nav-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.room-bg{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width: 100%;
}
.priceslist{
  font-family: sans-serif !important;
  font-size: 17px !important;
}
.myreservations{
  font-size: 38px;
  font-weight: 400;
  color: white;
}
.fees{
  font-family: sans-serif !important;
}
.footer p {
  margin-bottom: 0px;
  margin: 10px;
  font-weight: 400;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #42459b;
  color: white;
  text-align: center;
}
.main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.nav-links-last {
  padding: 0px !important;
}
.nav-links:hover {
  color: white;
}
.last-item {
  border-left: 1px solid white;
  height: 100%;
  text-align: center;
  padding: 10px;
  color: white;
}
.circle {
  width: 100px;
  height: 100px;
  background: red;
  border-radius: 50%;
}
.nav-logo {
  width: 120px;
  color: white;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: -28px;
}
.logo {
  height: 120px;
}
.nav-menu {
  height: 100%;
  margin-bottom: 0px;
  display: flex;
  list-style: none;
  text-align: center;
  justify-content: space-between;
  width: 100%;
}
.border-lng {
  margin-left: auto;
  margin-right: auto;
}
.indicator-arrow {
  width: 0;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #fff;
  position: absolute;
  bottom: 0;
}
.nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
}
.border-lng {
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  color: #d2d2d2;
  height: 24px;
  width: 24px;
}
.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  align-self: center;
  line-height: 40px;
}

.menu-items{
  width: 35%;
}

.nav-item:hover:after {
  width: 100%;
  background: white;
}

.nav-item .active {
  color: white;
  border-bottom: 1px solid white !important;
}
.iIlfol{
  background: #42459b !important;
}
.nav-icon {
  display: none;
}
.App {
  padding-top: 10vh;
  padding-bottom: 10vh;
  min-height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginApp {
  min-height: 83vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signupApp{
  width: 40%;
  margin: auto;
}
.signup-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}
.SignupSuccess{
  font-weight: 400;
  color: #42459b;
}
.SignupSuccess h1{
  font-weight: 500;
  font-family: 'Playfair Display', serif;
  color: #42459b;
}
.SignupSuccess h3{
  font-weight: 500;
  font-family: 'Playfair Display', serif;
  color: #42459b;
  font-size: 25px;
}
.app-field-message {
  padding: 10px 0 5px;
  font-size: 10px;
  line-height: 1.5;
  font-weight: 500;
  color: #707070;
}
.country-input{
  margin-top: 4px;
  border: none;
  border-bottom: 1px solid #242424;
  border-radius: unset;
}
.menu-flags ul{
  background: #f1f2f2;
  font-weight: 400;
}
.menu-flags div{
  background: #f1f2f2;
}
.menu-flags input{
  background: #f1f2f2;
}
.signupApp input{
  height: 30px;
}
.terms-and-conditions{
  padding: 10px 0 5px;
  font-size: 10px;
  line-height: 1.5;
  font-weight: 500;
  color: #707070;
}

.PhoneInputInput{
  background: transparent;
  border: none;
  border-bottom: 1px solid #242424;
}

/********* NAVBAR && FOOTER *********/

.card-bookingreservation{
  background-color: #42459b;
  padding:15px;
  height: 100%;
}
.cardreservation{
  background: #967a50;
}
.card-bookingreservation select{
    padding: 6px 12px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d2d2d2;
    height: 48px;
    font-size: 1rem;
    color: #444;
    box-shadow: none;
    border-radius: 0;
}
.reservation-wrapper{
  height: 100%;
    padding: 7px;
    border: 1px solid #967a50;
}
.reservation-wrapper-2{
  height: 100%;
  padding: 7px;
  border: 1px solid #42459b;
}
.reservation-wrapper-2 button{
  background: white;
  margin-top: 15px;
  margin-left: 0px !important;
}
.card-bookingreservation button{
  background: white;
}
.Backoffice h1{
  font-size: 20px;
  font-weight: 600;
}
.Backoffice input{
  height: 40px;
}
.Backoffice select{
  height: 40px;
}
.note-resa{
  color: white;
  font-weight: 500;
  font-size: 14px;
}
.reservation-detail tr td{
  padding: 10px;
}
.reservation-detail tr{
  padding: 10px;
  border-bottom: 1px solid #967a50;
}
.table-title{
  font-weight: 600;
  font-size: 13px;
  margin-left: 5px;
  margin-bottom: 2px;
  font-family: 'Roboto';
}
.table-description{
  font-weight: 400;
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: 0px;
  font-family: 'Roboto';
}
.btns-reservationdetails{
  float:right;
}
.Backoffice button{
  height: 40px;
}
.Backoffice label{
  color: white;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}
.card-bookingreservation div button{
  margin-left: 20px !important;
}
.Backoffice{
  padding-top: 100px;
  padding-bottom: 50px;
}

/********* MAIN PAGE *********/
.title {
  font-weight: 500;
  margin: 0 0 0px;
  text-align: center;
  color: #42459b;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  color: #42459b;
  font-family: 'Playfair Display', serif;
}
.arrow-up {
  width: 0;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #fff;
}
.dateField {
  border-color: #ffffff;
  min-height: 20vh;
}
.dateField {
  background-color: #ffffff;
}
.dateField {
  background: none repeat scroll 0 0 #fff;
  color: #2f373b;
  float: left;
  padding: 16px 0 26px;
  position: relative;
  width: 50%;
  border: 2px solid transparent;
}
.dateField p.month {
  border-bottom: 1px solid #f1f2f2;
  color: #967a50;
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 10px;
}
#datePicker .dateField p,
#datePicker .ui-state-default,
#datePicker .ui-widget-content {
  color: #2f373b;
}
.dateField p {
  color: #2f373b;
  text-align: center;
}
.dateField p.day {
  font-size: 3.5rem;
  font-weight: 300 !important;
  line-height: 72px;
  margin: 0;
  padding-bottom: 18px;
  padding-top: 14px;
}

.dateField p.day,
.ui-datepicker-year {
  font-family: Lato, sans-serif;
}
.dateField .border {
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  color: #d2d2d2;
  height: 24px;
  width: 24px;
  position: absolute;
  bottom: 15px;
  margin: auto;
  left: 45%;
}
.datefield-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.datef p.month {
  border-bottom: 1px solid #f1f2f2;
  color: #967a50;
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 10px;
}
.datef .border {
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  color: #d2d2d2;
  height: 24px;
  width: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  position: relative;
    bottom: 15px;
}
.dateField .border {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}
.arrow {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAMAAABV0m3JAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAmVBMVEUAAAADEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcDEzcAAAAvoq4OAAAAMXRSTlMAb3l4o2mkBrH+W/0JvftOCg7I+EL3FNLzN/Ia2+0tGyLj5iQq6t8c3is08NZLTAOp7HDD/gAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAB+SURBVAjXXY/XDoJAEEUvIFWKdJEO0kHY//85Q8gG2Hm5J2eSKeB4MCVweBDx7kQiQVZU7eq0pyIDumFap7NM47Wn7bgedZ7v2AcF4Ts6KPqEAe3HSZrtmaVJfk4qyqoG6qosrju/Tdt1fTPcrxuneZ5G9o+FkB/rsG7bSvkPKssILFNFP90AAAAASUVORK5CYII=) no-repeat scroll 50% bottom transparent;
  display: block;
  height: 6px !important;
  margin-left: 5.5px !important;
  margin-top: 9px !important;
  position: relative;
  width: 10px !important;
}
.button-stepper {
  text-transform: uppercase;
  background-color: #f1f2f2 !important;
  border: 2px solid #907650;
  color: #907650;
  padding: 7px 20px;
  font-size: 16px;
  letter-spacing: 2px;
  border-radius: 5px;
}
.button-stepper:hover {
  text-transform: uppercase;
  background-color: #907650 !important;
  border-color: #907650;
  color: white;
  padding: 7px 20px;
  font-size: 16px;
  letter-spacing: 2px;
  border-radius: 5px;
}
.reservation_header {
  color: #031337;
}
.reservation_header,
.reservation_text {
  font-weight: 500;
  font-size: 0.125rem;
  letter-spacing: 0.1rem;
  display: block;
  text-align: right;
  line-height: 1.8rem;
}
.reservation_text,
.reservation_hint {
  color: #5b5b5b;
}
.booking-button_wrapper {
  text-align: center;
  margin-top: 15px;
}
.reservation_text {
  font-family: Lato, sans-serif;
  color: #999;
}
.reservation_header,
.reservation_text {
  color: #031337;
  font-weight: 500;
  font-size: 1.125rem;
  letter-spacing: 0.1rem;
  display: block;
  text-align: right;
  line-height: 1.8rem;
}
.reservation_text,
.reservation_hint {
  color: #5b5b5b;
}
.reservation_hint {
  font-size: 0.875rem;
  font-weight: 400;
  color: #5b5b5b;
  line-height: 1.8rem;
  display: block;
  text-align: right;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 90px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: -1;
}
.vis-datepicker {
  position: relative;
  bottom: 25px;
  visibility: hidden;
}
/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}
.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* The container <div> - needed to position the dropdown content */
.dropdown-rooms {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content-rooms {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 100%;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content-rooms a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}
.dropdown-content-rooms a:hover {
  background-color: #ddd;
}

.dropdown-rooms:hover .dropdown-content-rooms {
  display: block;
}

@media screen and (max-width: 960px) {
  .current-room{
    position: unset !important;
  }
  .modal-rooms{
    padding: 0px !important;
    flex-direction: column !important;
  }
  .notes-list-hotel{
    list-style: none !important;
  }
  .reservation_wrapper{
    margin-bottom: 20px;
  }
  .logo {
    height: 80px;
  }
  .nav-links{
    display: none !important;
  }
  .nav-menu {
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 0px;
    left: 0%; 
    opacity: 1;
    transition: all 0.5s ease;
  }
  .Backoffice{
    padding-top: 20px;
  }
  .form-edit-user form{
    width: 100% !important;
  }
  .main-header{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .btn_bepartner{
    position: relative !important;
  }
  .login-row {
    padding: 40px 10px 10px 10px !important;
  }
  .border-l {
     border-right: none !important; 
  }
  .login-row{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: start !important;
  }
  .loginicons{
    flex-wrap: wrap;
  }
  .signupApp{
    width: 100% !important;
  }
  .card-form-payment{
    flex-direction: column !important;
  }
  .main-header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 13px;
    flex-wrap: wrap;
    justify-content: center;
    padding: -1px;
}
  .modal-markup .modal-content{
    padding: 0px !important;
  }
  .modal-markup .modal-body{
    padding-right: 0px !important;
  }
  .notes-list{
    margin-left: 0px !important;
  }
   .rate__room--listing span{
     font-size: 10px;
   }
  .rate__content{
    margin-left: 0px !important;
  }
  .sidebar{
     width: 100% !important;
  }
  .card-notes{
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 10px;
  }
  .card-rooms{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .footer p{
    font-size: 9px;
  }
  .App {
    padding-top: 10px;
  }
  .main-page{
    position: relative;
  }
  .dropdown-guest {
    padding: 20px;
    position: fixed !important;
    top: 0px !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 9999 !important;;
    height: 100% !important;;
  }
  .li-guest {
    padding: 8px 12px !important;
}
.addrow{
  margin-top: 10px !important;
}
  .ul-guest{
    display: block !important;
  }
  .reservation_header{
    font-size: 15px;
  }
  .guests-mb{
    margin-top: 15px;
    position: unset !important;
  }
  .dateField{
    min-height: 150px;
  }
  .datef{
    min-height: 150px !important; 
    

  }
  .title {
    font-size: 1rem;
    margin-top: 10px;
  }

  .booking-button_wrapper {
    margin-top: 10px;
  }

  .dateField p.day {
    font-size: 2.375rem;
    font-weight: 300 !important;
    line-height: 20px;
    margin: 0;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .button-stepper {
    text-transform: uppercase;
    background-color: #f1f2f2 !important;
    border: 2px solid #907650;
    color: #907650;
    padding: 5px 15px;
    font-size: 15px;
    letter-spacing: 2px;
    border-radius: 5px;
    margin-top: 10px;
  }

  .button-stepper:hover {
    text-transform: uppercase;
    background-color: #907650 !important;
    border-color: #907650;
    color: white;
    padding: 5px 15px;
    font-size: 15px;
    letter-spacing: 2px;
    border-radius: 5px;
    margin-top: 10px;
  }

  .reservation_header {
    text-align: center;
  }

  .reservation_text {
    text-align: center;
  }

  .reservation_hint {
    text-align: center;
  }

  .nav-menu.active {
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item .active {
    color: white;
    border: none;
  }

  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: white;
  }

}
.sidebar-left_wrapper .sidebar {
  padding: 50px 12% 50px 22%;
}
h5 {
  font-size: 1.2rem;
  padding-bottom: 0;
  text-transform: uppercase;
  font-weight: 400 !important;
}

/********* MAIN PAGES *********/


/********* MEDIA QUERY *********/
@media (min-width: 1200px) {
  .widget-booking .widget-booking__dates {
    padding: 1em 2em 1.5em;
  }
}
@media (min-width: 992px) {
  .widget-booking .widget-booking__dates {
    overflow: hidden;
  }
}
@media (min-width: 992px) {
  .widget-booking .widget-booking__dates {
    display: block;
    padding: 1em 1em 1.5em;
  }
}
@media (min-width: 1200px) {
  .widget-booking .widget-booking__date-block {
    padding: 1em 1.5em;
  }
}
@media (min-width: 992px) {
  .widget-booking .widget-booking__date-block--checkin {
    float: left;
  }
}
@media (min-width: 992px) {
  .widget-booking .widget-booking__date-block {
    padding: 0.5em 1em;
    border: 1px solid #967a50;
  }
}
/********* MEDIA QUERY *********/

/* BOOKING PAGES CSS */

.widget-booking .widget-booking__dates {
  display: inline-block;
  letter-spacing: 1px;
  margin-bottom: 0;
  padding: 1em 2em 1.5em;
  text-transform: uppercase;
}
.widget-booking .widget-booking__date-block {
  border-color: #967a50;
}


.widget-booking .widget-booking__date-block--checkin,
.widget-booking .widget-booking__date-block--checkout {
  display: inline-block;
  text-align: center;
}
.widget-booking .widget-booking__date-block {
  background-color: #fff;
  padding: 0.75em 1.5em;
}
.widget-booking .widget-booking__date-label {
  font-size: 9px;
  color: #999;
  font-weight: 700;
  margin-bottom: 1em;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.widget-booking .widget-booking__date-block--checkin,
.widget-booking .widget-booking__date-block--checkout {
  display: inline-block;
  text-align: center;
}
.widget-booking .widget-booking__date-label {
  font-size: 9px;
  color: #999;
  font-weight: 700;
  margin-bottom: 1em;
}
.widget-booking .widget-booking__month-year {
  font-size: 10px;
  font-weight: 600;
  display: block;
}
.secondary,
.offer_price,
.offer_price span,
.offer_price span:not(.amount),
.event_date_wrapper,
.event2-carousel .event-date-wrapper,
.event_date_zone,
.post_date_wrapper,
.author-meta .name,
.comment-body .author-name,
.comment-body .reply i,
.calluna-toggle .calluna-toggle-trigger.active,
.calluna-pricing .calluna-pricing-cost,
.calluna-pricing .calluna-pricing-per,
.calluna-testimonial-author,
.icon-clock,
.simple-weather i,
.pre,
.modal-price,
.single-room .booking-price_wrapper .amount,
.room-loop__list li.room .button--view-room-details,
table.hotelier-table .reservation-table__room-link:hover,
table.hotelier-table .view-price-breakdown:hover,
.single-room .room-meta-wrapper a:hover,
.single-room .room__rates .rate__conditions ul,
.hotelier-listing .selected-nights:before,
form.form--listing .room__only-x-left,
form.form--listing .room__more-link:hover,
form.form--listing .room__gallery-link:hover,
form.form--listing .room__conditions ul,
form.form--listing .rate__conditions ul,
form.form--listing .room__max-guests .max:after,
ul.reservation-details__list strong,
.widget-rooms-filter li:hover a,
.widget-rooms-filter li:hover a:before,
.widget-rooms-filter li.widget-rooms-filter__group-item--chosen a:before,
.widget-rooms-filter li.widget-rooms-filter__group-item--chosen a:hover:before,
.widget-booking .widget-booking__day,
.widget-booking .widget-booking__rooms-list a:hover,
.widget-rooms .widget-rooms__name:hover,
#hotelier-datepicker .datepicker-input-select-wrapper:before {
  color: #967a50;
}
.widget-booking .widget-booking__day {
  font-size: 26px;
  font-weight: 500;
  color: #967a50;
}
.widget-booking .widget-booking__day-name,
.widget-booking .widget-booking__month-year {
  font-size: 10px;
  font-weight: 600;
  display: block;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.widget-booking .widget-booking__date-block--checkin,
.widget-booking .widget-booking__date-block--checkout {
  display: inline-block;
  text-align: center;
}

.sidebar-left_wrapper .sidebar {
  padding: 50px 12% 50px 22%;
}
.sidebarcontainer{
  position: -webkit-sticky;
  position: sticky;
  top: 15px;
}
.sidebarcontaineropacity{
  opacity: 0.4;
    position: -webkit-sticky;
    position: sticky;
    top: 15px;
}
.sidebar_wrapper{
  position: relative;
}
.sidebar {
  margin: auto;
  padding: 10px;
  width: 70%;
  border: 1px solid #967a50;
  background: #EAE9EE;
}
.widget-rooms-filter .widget-rooms-filter__group-label {
  background-color: #967a50;
}

.widget-rooms-filter .widget-rooms-filter__group-label {
  font-size: 10px;
  background-color: #967a50;
  color: #fff;
  display: block;
  font-family: Lato, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 1em;
  padding: 0.5em 1em;
  text-transform: uppercase;
  cursor: pointer;
}
a:hover,
a:focus,
.primary,
.headline,
.page-header,
.sidebar .widget:not(.widget--hotelier) ul li a:hover,
.sidebar .widget:not(.widget--hotelier) ul li a:focus,
.prev-post a:hover,
.prev-post a:focus,
.next-post a:hover,
.next-post a:focus,
.more-link:focus,
.more-link:hover,
blockquote p,
ul.post-sharing label,
.image-row span,
.simple-weather em,
.comment-form,
.time,
.calluna-toggle .calluna-toggle-trigger,
.calluna-testimonial-author span,
.calluna-callout-caption h1,
.calluna-callout-caption h4,
.calluna-callout-caption h5,
.calluna-shortcodes h1.calluna-heading,
.calluna-shortcodes h4.calluna-heading,
.calluna-shortcodes h5.calluna-heading,
.event2-carousel .event-title-wrapper .event-title h3,
.event2-carousel .event-title-wrapper .event-title h3 a,
.woocommerce .woocommerce-info:before,
.room_name a,
.price-text,
.hotelier-pagination ul .page-numbers,
.room__deposit,
.rate__deposit,
.room-loop__list li.room .room__price .amount,
table.hotelier-table .reservation-table__room-link,
table.hotelier-table .view-price-breakdown,
table.hotelier-table
  .price-breakdown-wrapper
  table
  .price-breakdown__day--heading,
table.hotelier-table
  .price-breakdown-wrapper
  table
  .price-breakdown__cost--heading,
.single-room .room__gallery-link,
.single-room .room-meta-wrapper,
.single-room .room-available-rates a,
form.form--listing .room__more-link,
form.form--listing .room__gallery-link,
.widget-rooms-filter a,
.widget-booking .widget-booking__rooms-list a,
.widget-booking .amount,
.widget-rooms .widget-rooms__name,
.widget-rooms .widget-rooms__price .amount {
  color: #42459b;
}
.widget-rooms-filter a {
  font-size: 10px;
  color: #42459b;
  display: block;
  font-family: Lato, sans-serif;
  letter-spacing: 1px;
  margin-bottom: 0.3em;
  padding: 0.4em 0 0.4em 3em;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
}
.widget-rooms-filter a:before {
  font-size: 16px;
  color: #d2d2d2;
  content: "\f0c8";
  font-family: FontAwesome;
  margin-right: 1em;
  position: absolute;
  left: 2px;
  top: -2px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
ul {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}
.availibility {
  margin-left: 20px;
}
.pad-5 {
  background-color: white;
  padding: 50px;
}
.flatpickr-2 {
  height: 40px;
  border: 1px solid #907650;
  border-radius: 4px;
  padding: 10px;
}
.selected-nights {
  margin-top: 20px;
  font-size: 12px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  border-bottom: 1px solid #f1f2f2;
}
.selected-nights:before {
  font-size: 18px;
  color: #967a50;
  content: "\f14a";
  font-family: FontAwesome;
  margin-right: 0.75em;
  position: relative;
  top: 2px;
}
.button-stepper-availibity {
  margin: 0px !important;
  text-transform: uppercase;
  background-color: #907650 !important;
  border: 2px solid #907650 !important;
  padding: 7px 10px;
  font-size: 13px;
  letter-spacing: 2px;
  color: white;
  width: 130px;
}

.button-stepper-availibity:hover {
  text-transform: uppercase;
  background-color: #907650 !important;
  border: 2px solid #907650 !important;
  padding: 7px 10px;
  font-size: 13px;
  letter-spacing: 2px;
  color: white;
}
.button-stepper-showrates {
  text-transform: uppercase;
  background-color: #907650 !important;
  border: 2px solid #907650 !important;
  padding: 7px 10px;
  font-size: 13px;
  letter-spacing: 2px;
  color: white;
  width: 130px;
  margin-top: 20px;
}

.button-stepper-showrates:hover {
  text-transform: uppercase;
  background-color: #907650 !important;
  border: 2px solid #907650 !important;
  padding: 7px 10px;
  font-size: 13px;
  letter-spacing: 2px;
  color: white;
  margin-top: 20px;
}
.button-stepper-showrates:focus {
  outline:unset !important;
}
.button-stepper-availibity:focus {
  outline:unset !important;
}
h4{
  font-weight: 400;
}
.button-stepper-Reserve {
  background: #907650;
  border: 2px solid #907650;
  color: white;
  padding: 7px 20px;
  font-size: 16px;
  letter-spacing: 2px;
  font-family: 'Raleway', sans-serif;
  margin-right: 0px;
  margin-bottom: 65px;
}
.button-stepper-Reserve:hover {
  font-family: 'Raleway', sans-serif;
  background-color: #907650;
  border: 2px solid #907650;
  color: white;
  padding: 7px 20px;
  font-size: 16px;
  letter-spacing: 2px;
}
.rooms-page{
    background: white;
}
.widget-stay{
  font-weight: 400;
  font-size: 15px;
}
.card-rooms {
  margin-left: 0px;
  margin-top: 30px;
}
.card-notes{
  margin-left: 0px;
}
.notes-list{
  list-style: disc;
  margin-left: 15px;
  font-weight: 400;
  font-size: 13px;
}
.title-edit{
  margin-bottom: 0px;
  font-size: 1.2rem;
  padding-bottom: 0;
  text-transform: none !important;
  font-weight: 400 !important;
  font-family: 'Playfair Display', serif;
}
.title-edit-p{
  margin-top: 5px;
  font-size: 0.9rem;
  padding-bottom: 0;
  font-weight: 400 !important;
}
.rate__content{
  margin-left: 26%;
}
.manage-rooms-selection{
  font-weight: 400;
}
.managerooms{
    font-size: 18px !important;
    border: 1px solid #42459b;
    margin-left: 10px;
    padding: 10px;
}
.fa-pen-square:before {
  font-size: 25px;
}
.room__price--listing{
font-weight: 400;
}
.room {
  background: white;
  padding: 10px;
  border: 1px solid #967a50;
}
.facilities{
  font-weight: 400;
}
.facilities-icon i{
  font-size: 10px;
  color:#967a50;
}
.facilities-icon p{
  margin-left: 5px;
}
.room__gallery-link--listing {
  display: inline-block;
  font-size: 10px;
  color: #42459b;
  font-family: Lato, sans-serif;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  margin-top: 6px;
}
.widget-title {
  color: #42459b;
  font-size: 1.6rem;
  font-weight: 400;
  font-family: 'Playfair Display', serif;
  text-transform: none;
  line-height: 1.3;
}
.room__description {
  font-size: 15px;
  color: #999;
  line-height: 2rem;
}
.room__description--listing p {
  font-size: 16px;
  font-weight: 400;
  color: #42459b;
  line-height: 19px;
}
.fa-users{
  color: #967a50;
  font-size: 14px;
}
.room__more-link {
  font-size: 10px;
  color: #42459b;
  font-family: Lato, sans-serif;
  text-transform: uppercase;
  text-decoration: underline;
}

.facility-grp p {
  background: #f1f2f2;
  color: white;
  padding: 2px 5px 5px 9px;
  margin-left: 2px;
  color: #42459b;
}
.facilities p{
  font-size: 13px;
}
.room__max-guests .room__max-guests-label {
  font-weight: 400;
  font-size: 12px;
}
.room__max-guests .max.max2:after {
  content: "\f007\f007";
}
.room__max-guests .max:after {
  color: #967a50;
  content: "\f007";
  float: left;
  font-family: FontAwesome;
  letter-spacing: 3px;
  text-indent: 0;
}
.rate__price,
form.form--listing .room__price {
  font-size: 14px;
  color: #242424;
  display: block;
}
.amount {
  font-style: normal;
  font-weight: 600;
}
.yourreservationdet {
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0.5rem;
  font-size: 15px;
}
.reservation-room-total{
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0.8rem;
  font-size: 15px;
}
.reservation-room-nbr{
  font-weight: bold;
}
.room__price-description {
  font-size: 12px;
  font-style: normal;
  display: block;
  margin-bottom: 1.5em;
  font-size: 15px;
  color: #999;
  line-height: 2rem;
}
.notes{
  padding: 25px 10px 25px 10px;
  border: 1px solid #967a50;
}
.notes p{
  padding: 25px 10px 25px 10px;
  margin-top: 10px;
}
.widget-booking__cart-total {
  overflow: hidden;
  text-transform: uppercase;
}
strong {
  font-weight: 700;
}
#loader.loader-style {
  border-bottom-color: #967a50;
}
h3 {
  font-size: 2.125rem;
  line-height: 2.625rem;
  letter-spacing: 0.1em;
  font-weight: 400;
  color: #42459b;
  text-transform: uppercase;
  margin-bottom: 20px;
}
label {
  font-size: 1.25rem;
  letter-spacing: 0.072em;
  font-weight: 500;
  margin-bottom: 15px;
  color: #444;
  margin-top: 15px;
}
input,
textarea {
  background-color: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 0;
  color: #444;
}
input,
textarea {
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d2d2d2;
  height: 48px;
  font-size: 1rem;
  color: #444;
  box-shadow: none;
  border-radius: 0;
}
textarea {
  min-height: 150px;
}
.form-row__description {
  font-size: 12px;
  display: block;
  margin-top: 20px;
}
.dropdown-login {
  width: 150px;

  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  color: white;
  border-left: 1px solid white;
  height: 100%;
}

.dropdown-login-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-login-body {
  padding: 5px;
  border-top: 1px solid #e5e8ec;
  display: none;
}
.Signin{
  color:black;
}
.dropdown-login-body.open {
  z-index: 9999;
  position: absolute;
  box-shadow: 0px -2px 9px -2px rgba(0,0,0,0.79);
  -webkit-box-shadow: 0px -2px 9px -2px rgba(0,0,0,0.79);
  -moz-box-shadow: 0px -2px 9px -2px rgba(0,0,0,0.79);
  width: 100%;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  color: black;
  display: block;
}
.dropdown-login-item {
  padding: 10px;
}
.sidebarcontainer .spinner-border{
   position: absolute;
    left: 50%;
    top: 50%;
    right: 50%;
    bottom: 50%;
    color: #42459b;
}
.taxandfees{
  border-bottom: 1px solid #a19bbb;
  padding-bottom: 15px;
}
.sidebarcontaineropacity .spinner-border{
  position: absolute;
  left: 45%;
  top: 40%;
  right: 40%;
  bottom: 50%;
  color: #42459b;
  display: inline-block;
  width: 4rem;
  height: 4rem;
}
.dropdown-login-item:hover {
  cursor: pointer;
}

.dropdown-login-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.dropdown-login-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91a5be;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.form--login .form__title {
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 18px;
  width: 100%;
}
.form .form__meta {
  display: block;
  text-align: right;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
}
.form--login .field {
  background:transparent;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #242424;
  font-weight: 700;
  letter-spacing: 0.03em;
  color: #242424;
  padding: 12px 0;
  font-size: 13px;
}
.form--login .form__btn {
  margin-left: 0px;
  margin-top: 20px;
  display: block;
  margin-bottom: 20px;
  width: 100%;
  margin-bottom: 10px;
  line-height: 43px;
  padding: 0 15px;
  background-color: #816a35 !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.35px;
  font-weight: 700;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  border: 1px solid #816a35;
}
.loginicons i{
  color: #816a35;
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 8px;
}
.loginicons div{
  width: 100px;
  margin-right: 10px;
}
.loginicons p{
  text-align: center;
  font-size: 12px;
}
.loginicons{
  margin-top: 36px;
  display: flex;
  font-weight: 400;
}
.btn_bepartner{
  position: absolute;
  bottom: 23px;
}
.form--login .form__actions a {
  font-size: 12px;
  font-weight: 500;
  color: #242424;
  text-decoration: underline;
}
.login-row {
  z-index: 99999;
  padding: 70px;
  max-width: 1106px !important;
}
.form--login .form__title {
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 18px;
  color: #42459b;
}
.form__label {
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 15px;
  color: #42459b;
}
.form .form__meta {
  display: block;
  text-align: right;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.02em;
  margin-top: 30px;
}
.border-l {
  border-right: 1px solid #42459b;
}
.section__subtitle {
  font-size: 18px;
  margin-bottom: 10px;
}
.section__subtitle {
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 35px;
}
.section__entry p {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 400;
  color: #403a60;
  letter-spacing: 0.02em;
}
.link {
  position: relative;
  font-size: 14px;
  border: 0 none;
  background: none;
  outline: none;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #403a60;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.fa-times {
  float: right;
  cursor: pointer;
  right: 0px;
  position: absolute;
  top: 0px;
}
.dropbtn {
  z-index: 0;
}

@media only screen and (max-width: 600px) {
  .mobile-d {
    display: none !important;
  }

  .booking-button_wrapper{
    margin-top:0px !important;
  }
  .button-stepper{
    margin-top: 0px;
  }
  .button-stepper:hover{
    margin-top: 0px;
  }
  .App{
    align-items: baseline;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 601px) {
  .closev2{
    display: none;
  }
}
.footer p {
  font-size: 12px;
  cursor: pointer;
}
.fa-rev{
  font-size: 16px;
}
.container-guest {
  position: relative;
  display: inline-block;
}
.button-guest {
  padding: 0;
  width: 50px;
  border: 0;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  outline: 0;
  font-size: 40px;
}
.dropdown-guest {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400 !important;
  position: fixed !important;
  top: 100%;
  right: 15px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background: white;
  padding: 20px;
  top: 0px !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 9999 !important;
  height: 100% !important;
}
.room-nbr{
  margin-bottom: 0px;
  padding: 8px 12px 0px 12px;
  color: #42459b;
  letter-spacing: 0.1rem;
  justify-content: start;
  font-family: 'Playfair Display', serif;
  font-size: 1.3rem;
  font-weight: 500;
}
.ul-guest {
  list-style: none;
  padding: 0;
  margin: 0;
}
.li-guest {
  font-weight: 400 !important;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
  color: #42459b;
  display: flex;
  padding: 0px 12px;
  align-items: center;
  
}


.add-minusrow{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 14px;
  border:1px solid #42459b;
  margin-right: 10px;
  margin-left: 10px;
}
.guests-title{
  font-family: 'Playfair';
  font-size: 1rem;
  font-weight: 500;
  width:110px;
}
.guests-title-rec{
  font-size: 11px;

}
.sb-group-field-has-error {
  border-color: #c00;
}

.sb-group-field-has-error {
  border-color: #c00;
}
.sb-group__children__field select {
  margin: 16px 0 0 16px;
}
.sb-group-field-has-error {
  border: 1px solid #0071c2;
  color: #333;
  margin-left: auto;
  padding: 8px 2px 8px 8px;
  border-radius: 2px;
  margin:4px;
  background-position: right 8px top 50%;
  min-width: 48px;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
  color: #42459b;
  
}
.ul-guest{
  display: flex;
  justify-content: center;
}
.addrow{
  text-transform: uppercase;
  background-color: #f1f2f2 !important;
  border: 2px solid #907650;
  color: #907650;
  padding: 7px 20px;
  font-size: 11px;
  letter-spacing: 2px;
  border-radius: 5px;
  margin: 0px;
  margin-bottom: 5px;
  margin-right: 15px;
}
.gg-trash {
  box-sizing: border-box;
  position: relative;
  display: block;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform: scale(var(--ggs,1));
          transform: scale(var(--ggs,1));
  width: 10px;
  height: 12px;
  border: 2px solid transparent;
  box-shadow:
      0 0 0 2px,
      inset -2px 0 0,
      inset 2px 0 0;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  margin-top: 4px
}
.gg-trash::after,
.gg-trash::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute
}
.gg-trash::after {
  background: currentColor;
  border-radius: 3px;
  width: 16px;
  height: 2px;
  top: -4px;
  left: -5px
}
.gg-trash::before {
  width: 10px;
  height: 4px;
  border: 2px solid;
  border-bottom: transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  top: -7px;
  left: -2px
}
.d-none{
  display: none !important;
}
.addrow1-none{
  display: none !important;
}
.rooms-sh-nbr{
  font-weight: 500;
  margin: 0 0 0px;
  text-align: center;
  color: #42459b;
  font-size: 17px;
  letter-spacing: 0.1rem;
  color: #42459b;
  font-family: Lato, sans-serif;
  font-weight: 300 !important;
  font-weight: bold;
}
.nbrs-sh{
  font-weight: 500;
  margin: 0 0 14px;
  text-align: center;
  color: #42459b;
  font-size: 10px;
  letter-spacing: 0.1rem;
  color: #42459b;
  text-align: left !important;
  font-family: Lato, sans-serif;
  font-weight: bold;
}
.devider{
  border:1px solid #f1f2f2;
}
.datef{
  min-height: 211px;
  padding: 16px 0 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: none repeat scroll 0 0 #fff;
  color: #2f373b;
  float: left;
  padding: 16px 0 26px;
  position: relative;
  width: 75%;
  border: 2px solid transparent;
}
.datef .border{
  margin-top: 0px !important;
}
.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.managerooms{
  cursor: pointer;
}
.loading div {
  position: absolute;
  background: #42459b;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  -webkit-animation-delay: -.7s;
          animation-delay: -.7s;
}
@-webkit-keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
.preloader-container{
  height: 100vh;
  display: flex;
  justify-content:center;
  align-items: center;
}
.main-page {
  -webkit-animation: fade 1.5s;
          animation: fade 1.5s;
}
@-webkit-keyframes fade {
  from {opacity: 0;}
  to {opacity: 1;}
} 

@keyframes fade {
  from {opacity: 0;}
  to {opacity: 1;}
}

.dateField{
  cursor: pointer;
}

.room{
  cursor: pointer;
}
.showrates{
  border: 0;
  font-size: 13px;
}
.showrates:hover{
  border: 0;
  font-size: 13px;
}
.rate__deposit, .room__deposit {
  font-size: 10px;
  background-color: #f1f2f2;
  border-radius: 120px;
  color: #42459b;
  display: inline-block;
  font-family: Lato,sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}
.rate__deposit, .room__deposit {
  font-size: 10px;
  background-color: #f1f2f2;
  border-radius: 120px;
  color: #42459b;
  display: inline-block;
  font-family: Lato,sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.room__rate--listing {
  margin-top: 15px;
  border-top: 1px solid #f1f2f2;
  padding: 20px;
  font-weight: 400;
}
.policy{
  color: #907650 !important;
  font-size: 13px !important;
  margin-bottom: 1px;
}
.max.max2:after {
  content: "\f007\f007";
}
.main-page-none{
  display: none !important;
}
.rate__room{
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  color: #42459b;
}
.promotion-description{
  color: rgb(168, 20, 20);
}
.promotion-description div{
  display: flex;
  font-weight: 400;
  margin-top: 5px;
}
.promotion-description p{
  margin-bottom: 0px;
 }
.modal-dialog-centered .modal-content{
  background-color: transparent;
  color: white !important;
  border:none !important;
  font-family: Raleway;
}
.modal-sm{
  max-width: 1224px !important;
}
.room-selection-modal{
  width: 320px;
  padding: 15px;
  border: 1px solid white;
  background-color: white;
  color: black;
  border-radius: 10px;
}
.modal-backdrop.show {
  opacity: .8;
}
.current-room{
  position: absolute;
  top: -24px;
}
.modal-dialog-centered .modal-content{
  box-shadow: none !important;
}
.modal-rooms{
  padding:50px;
  background: #EAE9EE;
}
.sidebar-rooms-modal{
  margin: auto;
  padding: 10px;
  border: 1px solid #967a50;
  background: #EAE9EE;
  color: black;
  font-weight: 400;
}
.cursor-pointer{
  cursor: pointer;
}
.modal-rooms .modal-body{
  flex:unset !important;
}
.btn-primary:not([disabled]):not(.disabled):active{
  background-color: unset !important; 
   box-shadow: unset !important;
}
.invalid{
  border:1px solid red !important;
}
.fa-user{
  color: #967a50;
  margin-left: 10px;
}
.add-to-cart-selected {
  font-size: 9px;
  display: block;
}
.yourreservation{
  padding-bottom: 14px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: yellow;
}
.disabled-reserve-btn{
  opacity: 0.4;
  cursor: no-drop !important;
}
.srv-validation-message{
  color: red;
  font-size: 13px;
  margin-top: 4px;
  font-weight: 400;
}
.form__controls :focus-visible{
  outline:none !important;
}
.sectionguest-header__title{
  font-family: 'Playfair Display', serif;
  text-transform: unset;
  letter-spacing: unset;
  margin-bottom: 0;
}
.sectionguest-title__title{
  font-family: 'Playfair Display', serif;
  text-transform: unset;
  letter-spacing: unset;
  margin-bottom: 0;
  font-size: 25px;
  margin-top: 15px;
}
.sectionguest-title{
  font-family: 'Playfair Display', serif;
  text-transform: unset;
  letter-spacing: unset;
  margin-bottom: 0;
  font-size: 17px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.fa-arrow-left{
  font-size: 25px;
  color: #42459b;
}
.card-form{
  border: 1px solid #967a50;
  display: flex;
  flex-direction: column;
  padding: 0px 5px 0px 5px;
}
.form-input-guest{
  border: none;
  padding: 0px;
  height: 20px;
}
.form-input-label{
  font-size: 15px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.sidebar-guests{
  width: 100% !important;
}
.menu-flags-guest{
  font-weight: 400;
}
.menu-flags-guest-btn{
  border: 1px solid #967a50;
  font-weight: 400;
}
.special_request{
  border: 1px solid #967a50;
}
.card-form-payment{
  padding:15px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.card-form-payment h5{
  font-weight: 600 !important;
  text-transform: none;
  color: #42459b;
  font-size: 16px;
  margin-bottom: 5px;

}
.card-form-payment p{
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 3px;
  margin-top: 3px;
}
.payment-cards p{
  font-weight: 600;
  font-size: 18px;
  font-family: "Roboto";
  color: #42459b;
}
.payment-cards input{
  height: 37px;
}
.card-form-secure{
  padding:15px;
}
.card-form-secure p{
  font-weight: 400;
  font-size: 9px;
}
.card-form-secure h5{
  font-weight: 600 !important;
  text-transform: none;
  color: #42459b;
  font-size: 16px;
  margin-bottom: 5px;
}
.cancellation-policytxt{
  font-size: 13px !important;
  font-weight: 400;
}
.Credit{
  color: white;
  font-family: 'Roboto';
}
.card-credit label{
  color: white !important;
}
.form-edit-user form{
  width: 60%;
  
}
.form-edit-user{
display: flex;
justify-content: center;
background: white;
}
.form-edit-user label{
  color: black !important;
}
.btn-action{
  margin-left: 0px;
  margin-top: 20px;
  display: block;
  margin-bottom: 20px;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 15px;
  background-color: #816a35 !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.35px;
  font-weight: 700;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  border: 1px solid #816a35;
}
.rdt_Pagination{
  font-weight: 700 !important;
}
.modal-markup .modal-content{
  background: white;
  border-radius: 10px;
  color:black;
  padding: 20px;
}
.modal-markup .modal-content h4{
  color: black;
}
.modal-markup .modal-content p{
  color: black;
  font-weight: 400;
  font-size: 13px;
}
.btn-action-markup{
  margin-left: 0px;
  margin-top: 20px;
  display: block;
  height: 43px;
  margin-bottom: 20px;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 15px;
  background-color: #816a35 !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.35px;
  font-weight: 700;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  border: 1px solid #816a35;
}
.no-rooms-availibility{
  font-weight: 400;
  text-align: center;
}
/******* END BOOKING PAGES CSS *********/
.resetpasswordbtn{
  color: red;
  font-weight: 400;
  text-align: right;
  font-size: 12px;
  cursor: pointer;
}
.resetpassff{
  width: 700px;
}
input:focus-visible {
  outline: unset;
}
.credit{
  font-weight: 400;
}
.credit strong{
  font-family: monospace;
}
.flat-pickrcredit{
  width: 280px;
}
.datatable-ff{
  font-weight: 400 !important;
}

.btnsearch{
background: white !important;
border-radius: 5px;
box-shadow: none;
width: 150px;
opacity: 1 !important;
margin-top: 0px;
font-family: 'Raleway', sans-serif;
border:none;
}

.btnsearch span{
  color: #42459b !important;
}
.nightsNbr{
  font-weight: 600;
}
.retry{
  color: white;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(139, 133, 133, 0.02) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::-webkit-input-placeholder {
  color: #aab7c4;
}

input:-ms-input-placeholder {
  color: #aab7c4;
}

input::placeholder {
  color: #aab7c4;
}


.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
.leftbtnstripe{
  display:flex;
  justify-content: space-between;
}
.clic2paybtn{
  display:flex;
  justify-content: space-between;
}
